<template>
  <div
    class="block cursor-pointer whitespace-nowrap"
    :class="{ 'cursor-wait': bookingPending }"
    @click="!bookingPending && useResetStep(data)"
  >
    <div>{{ deckName }}</div>
    <div>{{ deckNumber }}</div>
    <div>{{ data.cabin }}</div>
  </div>
</template>

<script setup lang="ts">
import type { CabinStep } from "~~/types/order";

const props = defineProps<{
  data: CabinStep;
}>();
const deckName = computed(() => props.data.deck_name.split(" - ")[0]);
const deckNumber = computed(() => props.data.deck_name.split(" - ")[1]);

const { bookingPending } = useBookingPending();
</script>
