<template>
  <NuxtLink :to="to" :class="{ 'cursor-wait': !to }">
    <div>{{ data.adults_count }}</div>
    <div>{{ data.children_count }}</div>
    <div>{{ data.loyalty_program_count }}</div>
    <div class="whitespace-nowrap uppercase text-teal">
      <span v-html="data.price_per_person.formatted"></span> pro person
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { OccupancyStep } from "~~/types/order";

const props = defineProps<{
  data: OccupancyStep;
}>();

const { bookingPending } = useBookingPending();

const to = computed(() => {
  return bookingPending.value ? undefined : props.data.context;
});
</script>
