<template>
  <div
    class="flex cursor-pointer flex-col"
    :class="{ 'cursor-wait': bookingPending }"
    @click="!bookingPending && useResetStep(data)"
  >
    <span>{{ data.cabin_type_name }}</span>
    <span>{{ data.tariff_title }}</span>
    <span>{{ data.category_name }}</span>
  </div>
</template>

<script setup lang="ts">
import type { CategoryStep } from "~~/types/order";

defineProps<{
  data: CategoryStep;
}>();

const { bookingPending } = useBookingPending();
</script>
