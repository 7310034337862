<template>
  <NuxtLink :to="to" class="max-w-60 shrink" :class="{ 'cursor-wait': !to }">
    <div class="font-bold">{{ data.ship }}</div>
    <div>{{ data.duration }}</div>
    <div class="normal-case">{{ data.departures }}</div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { CruiseInfoStep } from "@/types/order";
const props = defineProps<{
  data: CruiseInfoStep;
}>();

const { bookingPending } = useBookingPending();

const to = computed(() => {
  return bookingPending.value ? undefined : props.data.context;
});
</script>
